<template>
    <div class="main-box">
        <div class="con-box">
            <div class="title">
                <p>
                    <span style="color: #CECECE;">我的服务 > </span>
                    <span>审批详情</span>
                </p>
            </div>
            <div class="content-box">
                <a-table :columns="columns" :data-source="logLists" :pagination="false">
                    <template #status>
                        <img src="../assets/images/service-check.png">
                    </template>
                </a-table>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
export default({
    computed: {
        ...mapGetters(['isAuth', 'loginType'])
    },
    setup() {
        const columns = [
            {
                title: '业务流程',
                dataIndex: 'key',
                key: 'key',
                align:'center'
            },
            {
                title: '时间',
                dataIndex: 'datetime',
                key: 'datetime',
                align:'center',
                width: 200
            },
            {
                title: '处理人',
                dataIndex: 'serviceItem',
                key: 'serviceItem',
                align:'center'
            },
            {
                title: '说明',
                dataIndex: 'indroduction',
                key: 'indroduction',
                align:'center'
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                align:'center',
                slots: {
                    customRender: 'status'
                }
            }
        ]
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            columns
        }
    },
    data () {
        return {
            logLists: []
        }
    },
    methods: {
        getDetail () {
            this.logLists = []
            this.$store.dispatch('getLinkEnterProcLogDetail', {
                showCount: -1,
                currentPage: 1,
                LINKENTERPROC_ID: this.id,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    if (res.varList.length != 0) {
                        let datas = res.varList
                        for (let i = datas.length - 1, len = datas.length; i >= 0; i --) {
                            this.logLists.push({
                                 key: datas[i].AUDIT_NAME,
                                datetime: datas[i].CTIME,
                                serviceItem: datas[i].AUDIT_UNAME,
                                indroduction: datas[i].REMARK
                            })
                        }
                    }
                } else {
                    this.$message.error('系统异常，获取列表失败，请稍后重试！')
                    this.logLists = []
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.id = this.$route.query.id
        this.getDetail()
    }
})
</script>
<style lang="less" scoped>
.content-box ::v-deep(.ant-table-thead){
    background: @color-blue; /* 标准语法 */
}
.content-box ::v-deep(.ant-table-thead th){
    background-color: transparent;
    color: @color-ff;
}
</style>
<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;

    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content-box{
            font-size: 16px;
        }
    }
    .con-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: @color-blue;; /* 标准语法 */
    }
}
</style>
